export default class Header {
  constructor() {
    this.header = '#header'
    this.$header = $(this.header)
    this.numberScrol = 0
    this.scrollTop = 0
    this.class = 'pin-header'
    this.$button = $('.close-alert')
    this.$search = $('.search-header')
  }

  init() {
    if (this.$header.length) {
      this.scrollPinHeader()
      this.settingScroll()
      this.closeAlert()
      this.openSearch()
    }
  }

  openSearch() {
    $('.open-search').on('click', () => {
      this.$search.toggleClass('active')
      this.$header.toggleClass('search-active')
      if (this.$search.hasClass('active')) {
        this.$header.find('.form-control').focus()
      }
    })
    $(document).on('click', (e) => {
      if (!$(e.target).closest('.search-header').length && !$(e.target).closest('.open-search').length && !$(e.target).is('.open-header')) {
        this.$search.removeClass('active')
        this.$header.removeClass('search-active')
      }
    })
  }

  scrollPinHeader() {
    this.settingPin()
    $(window).on('scroll resize orientationchange', () => {
      this.settingPin()
    })
  }

  settingPin() {
    this.scrollTop = $(window).scrollTop()
    if (this.scrollTop > this.numberScrol) {
      this.$header.addClass(this.class)
    } else {
      this.$header.removeClass(this.class)
    }
  }

  settingScroll() {
    let lastScrollTop = 0
    const delta = 20
    $(window).scroll(() => {
      const st = $(window).scrollTop()
      if (Math.abs(lastScrollTop - st) <= delta) {
        return
      }
      if (st > lastScrollTop && st >= 50) {
        this.$header.addClass('hide-header')
      } else {
        this.$header.removeClass('hide-header')
      }
      lastScrollTop = st
    })
  }

  closeAlert() {
    if (this.$button.length) {
      this.$button.on('click', () => {
        $('.alert-bar').removeClass('block').addClass('hidden')
      })
    }
  }
}
new Header().init()
