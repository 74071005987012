export default class UserAgent {
  constructor() {
    this.$html = $('html')
  }

  init() {
    this.removeNoscript()
    this.scrollToElmLoadPage()
    this.setHeightProductHeaderBG()
    $(window).on('load resize', () => {
      this.setHeightProductHeaderBG()
    })
    this.socialShare()
  }

  scrollToElmLoadPage() {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.search)
    const faqCat = searchParams.get('faq_cat')
    if (faqCat && $(`.btn-filter-item[data-category="${faqCat}"]`).length && $('.mod-faqs').length) {
      $('body, html').stop().animate({
        scrollTop: $('.mod-faqs').offset().top - 100 - $('.header').height(),
      }, 500)
    }
  }

  setHeightProductHeaderBG() {
    const $productHeader = $('.mod-product-header')
    const $bgBottom = $productHeader.find('.linear-gradient-bottom')
    if ($bgBottom.length) {
      const $bgTop = $productHeader.find('.linear-gradient-top')
      const height = $bgTop.innerHeight() - 2
      $bgBottom.css('top', height)
    }
  }

  socialShare() {
    const socialLink = $('.social-link')
    socialLink.on('click', (e) => {
      const currentTarget = $(e.currentTarget)
      if (currentTarget.hasClass('print')) {
        window.print()
      }
      if (currentTarget.hasClass('link')) {
        const linkIcon = window.location.href
        const textCopy = currentTarget.data('copied')
        navigator.clipboard.writeText(linkIcon)
        const tooltip = `<span class="tooltip">${textCopy}</span>`
        if ($('.tooltip').length) {
          setTimeout(() => {
            $('.tooltip').remove()
          }, 2000)
        } else {
          currentTarget.append(tooltip)
          setTimeout(() => {
            $('.tooltip').remove()
          }, 2000)
        }
      }
    })
  }

  removeNoscript() {
    $('#notify').remove()
  }
}

new UserAgent().init()
