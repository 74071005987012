import Swiper from 'swiper'
import {
  Pagination,
  Keyboard,
  A11y,
  EffectFade,
} from 'swiper/modules'
import VideoIframe from '../components/VideoIframe'
import CallBackLazy from '../components/CallBackLazy'

const callBack = new CallBackLazy()
export default class BannerSlider {
  constructor(el) {
    this.$el = $(el)
    this.$swiper = this.$el.find('.swiper-container')

    this.swiper = null
    this.playerVideos = []
    this.timeImage = null
    this.classes = {
      firstSlider: 'first-slider',
      lastSlider: 'last-slider',
      swiperPaginationBullet: 'swiper-pagination-bullet',
      swiperPaginationBulletActive: 'swiper-pagination-bullet-active',
    }
    this.$controlSlide = this.$el.find('.control-play')
    this.$buttonControlSlide = this.$controlSlide.find('.button-control-slide')
    this.$controlMute = this.$el.find('.control-mute')
    this.autoSlide = this.$el.attr('data-autoslide') === 'true'
    this.muteVideo = true
    this.$iconControl = this.$controlSlide.find('.icon-play')
    this.$textControl = this.$controlSlide.find('.text-control-slide')
    this.$iconControlMute = this.$controlMute.find('.icon-volume-off')
    this.$textControlMute = this.$controlMute.find('.text-control-slide')
    this.resizeText = this.$el.hasClass('auto-text-resize')
    this.autoNextSlide = this.autoSlide
    this.clickToControl = false
    this.clickToControlPlay = true
  }

  init() {
    this.addSwiper()
    this.setAutoPlay()
    $(window).on('resize scroll', () => {
      this.setAutoPlay()
    })
  }

  setAutoPlay() {
    const offsetTop = this.$el.offset().top
    const scrollTop = $(window).scrollTop()
    if (scrollTop + offsetTop > offsetTop + this.$el.height() / 3) {
      if (this.autoNextSlide) {
        if (this.$controlSlide.find('.icon-play').length) {
          this.$controlSlide.trigger('click')
        }
        this.autoNextSlide = false
      }
    } else if (!this.autoNextSlide) {
      if (this.$controlSlide.find('.icon-pause').length && this.clickToControlPlay) {
        this.$controlSlide.trigger('click')
      }
      this.autoNextSlide = true

    }
  }

  addSwiper() {
    this.swiper = new Swiper(this.$swiper[0], {
      modules: [Pagination, Keyboard, A11y, EffectFade],
      slidesPerView: 1,
      speed: 500,
      effect: 'fade',
      rewind: true,
      autoHeight: this.resizeText,
      keyboard: {
        enabled: true,
      },
      a11y: {
        enabled: true,
        prevSlideMessage: 'Previous slide',
        nextSlideMessage: 'Next slide',
        firstSlideMessage: 'This is the first slide',
        lastSlideMessage: 'This is the last slide',
        paginationBulletMessage: 'Go to slide {{index}}',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      on: {
        transitionStart: () => {
          clearTimeout(this.timeImage)
          window.lazyloadImage.lazyloadimageCustom()

        },
        transitionEnd: () => {
          this.autoPlaySlider()
        },
      },
    })
    this.swiper.controlAutoplay = this.autoSlide
    this.controlSlide()
    setTimeout(() => {
      this.autoPlaySlider()
    }, 2000)
  }

  autoPlaySlider() {
    this.playVideoSlideActive()
    clearTimeout(this.timeImage)
    if (!this.activeHasVideo() && this.autoSlide) {
      setTimeout(() => {
        this.$el.find('.lazy').each((_index, element) => {
          const elementTmp = element.tagName
          callBack.call(elementTmp, element)
        })
      }, 4000)
      this.timeImage = setTimeout(() => {
        this.swiper.slideNext()
      }, 6000)
    }
  }

  activeHasVideo() {
    const $active = this.$el.find('.swiper-slide-active')
    const player = $active.data('player')
    return player.type !== null
  }

  setVideoToStart(player) {
    if (player.player && player.player.seekTo) {
      player.player.seekTo(0)
    }
  }

  setupVideo($slider, play = true) {
    if ($slider.data('player')) {
      const player = $slider.data('player')
      const autoplayVideo = $slider.find('[data-autoplay="1"]')
      if (play && autoplayVideo.length > 0) {
        this.setVideoToStart(player)
        player.playVideo()
      } else {
        this.setVideoToStart(player)
        player.pauseVideo()
      }
      return
    }

    if (play) {
      const player = new VideoIframe($slider, this.swiper)
      $slider.data('player', player)
      if (player.type !== null) {
        player.init()
      }
    }
  }

  playVideoSlideActive() {
    this.$el.find('.swiper-slide').each((_index, element) => {
      this.setupVideo($(element), $(element).hasClass('swiper-slide-active'))
    })
  }

  controlSlide() {
    if (this.$controlSlide) {
      this.$buttonControlSlide.on('click', () => {
        setTimeout(() => {
          this.clickToControlPlay = this.$buttonControlSlide.find('.icon-play').length > 0
        }, 100)
      })
      this.$controlSlide.on('click', () => {
        this.autoSlide = !this.autoSlide
        this.swiper.controlAutoplay = this.autoSlide
        clearTimeout(this.timeImage)
        if (this.autoSlide) {
          this.autoPlaySlider()
          this.$iconControl.removeClass('icon-pause').addClass('icon-play')
          this.$textControl.text('Pause slide')
        } else {
          this.$iconControl.removeClass('icon-play').addClass('icon-pause')
          this.$textControl.text('Play slide')
        }
      })
    }
    if (this.$controlMute) {
      this.$controlMute.on('click', () => {
        this.muteVideo = !this.muteVideo
        const $videoSlides = this.$el.find('.swiper-slide')
        $videoSlides.each((_index, element) => {
          const mute = $(element).find('.control-video-mute')
          if (mute.length > 0) {
            mute.trigger('click')
          }
        })
        if (!this.muteVideo) {
          this.$iconControlMute.removeClass('icon-volume-off').addClass('icon-volume-up')
          this.$textControlMute.text('Unmute videos')
        } else {
          this.$iconControlMute.removeClass('icon-volume-up').addClass('icon-volume-off')
          this.$textControlMute.text('Mute videos')
        }
      })
    }
  }
}

if ($('.mod-banner-home, .mod-full-width-video').length) {
  $('.mod-banner-home, .mod-full-width-video').each((_i, el) => {
    new BannerSlider(el).init()
  })
}
