import {
  getYoutubeVideoId,
} from '../utils'

export default class VideoIframe {
  constructor(el, mySwiper = null) {
    this.$el = $(el)
    this.$video = this.$el.find('.background-video-item')
    this.mySwiper = mySwiper
    this.type = null
    this.player = null
    this.play = true
    this.mute = true
    this.nextSlide = true
    this.loaded = false
    this.src = this.$video.attr('data-src')
    this.autoplay = this.$video.attr('data-autoplay')
    this.loop = this.$video.attr('data-loop')
    this.control = this.$video.attr('data-control')
    this.$controlVideo = this.$el.find('.js-control-video')
    this.$controlVideoMute = this.$el.find('.control-video-mute')
    this.$iconControl = this.$controlVideo.find('.icon-control')
    this.$iconControlMute = this.$controlVideoMute.find('.icon-control')
    this.$textControl = this.$controlVideo.find('.js-text-control')
    this.$textControlMute = this.$controlVideoMute.find('.js-text-control')
    this.$imgVideo = this.$el.find('.img-video')
    if (this.src === undefined || this.src === null || this.src === '') {
      return
    }
    this.validateSrc()
  }

  init() {
    if (this.src === undefined || this.src === null || this.src === '') {
      return
    }
    this.startTime = performance.now()
    this.userInteracted = false
    this.setupUserInteractionListeners()
    this.delayLoadWithTime(3000)
  }

  setupUserInteractionListeners() {
    const handleUserInteraction = () => {
      if (!this.userInteracted) {
        this.userInteracted = true
        this.loadApiYoutube()
      }
    }

    $(window).on('mousedown keydown touchstart touchmove scroll click mousemove', () => {
      handleUserInteraction()
    })
  }

  delayLoadWithTime(delayMs) {
    let animationFrameId

    const checkTime = (timestamp) => {
      if (this.userInteracted) {
        // If user has already interacted, cancel the animation frame and stop
        window.cancelAnimationFrame(animationFrameId)
        return
      }

      if (timestamp - this.startTime >= delayMs) {
        this.loadApiYoutube()
      } else {
        animationFrameId = window.requestAnimationFrame(checkTime)
      }
    }

    animationFrameId = window.requestAnimationFrame(checkTime)
  }

  loadApiYoutube() {
    if (window.loadApiYoutube !== true) {
      window.loadApiYoutube = true
      // Load YouTube IFrame API
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      // Wait for API to load before running video
      window.onYouTubeIframeAPIReady = () => {
        this.runVideo()
      }
    } else {
      this.runVideo()
    }
  }

  runVideo() {
    if (this.type !== null && !this.loaded) {
      this.loaded = true
      const checkYoutubeAPI = () => {
        if (typeof window.YT !== 'undefined' && typeof window.YT.Player !== 'undefined') {
          this.observeVideo()
          this.controlVideo()
          this.controlVideoMute()
          this.clickThumb()
        } else {
          this.youtubeCheckId = requestAnimationFrame(checkYoutubeAPI)
        }
      }

      checkYoutubeAPI()
    }
  }

  validateSrc() {
    const youtubeVideoId = getYoutubeVideoId(this.src)
    if (youtubeVideoId) {
      this.type = 'youtube'
      this.id = youtubeVideoId
    }
  }

  lazyLoadVideo(isInViewport) {
    if (isInViewport && this.player === null) {
      this.player = new window.YT.Player(this.$video[0], {
        videoId: this.id,
        playerVars: {
          autoplay: this.autoplay,
          loop: this.loop,
          mute: 1,
          controls: this.control,
          rel: 0,
          playlist: this.id,
          modestbranding: 0,
          showinfo: 0,
          autohide: 1,
          fs: 0,
        },
        events: {
          onReady: () => {
            $(this.player.g).attr('aria-hidden', 'true')
            this.$el.addClass('video-loaded')
          },
          onStateChange: (e) => {
            if ((e.data === -1 && this.nextSlide)) {
              this.nextSlide = false
              this.nextSliderAfterEndVideo()
            }
            setTimeout(() => {
              this.nextSlide = true
            }, 100)
          },
        },
      })
    }
  }

  nextSliderAfterEndVideo() {
    if (this.mySwiper && this.mySwiper.controlAutoplay) {
      this.mySwiper.slideNext()
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect()
    return (
      rect.top < window.innerHeight && rect.bottom >= 0 &&
      rect.left < window.innerWidth && rect.right >= 0
    )
  }

  loadVideo() {
    const isInViewport = this.isInViewport(this.$el.get(0))
    this.lazyLoadVideo(isInViewport)
    if (this.player) {
      this.eventPlayOrPauseVideoOnDesktop(isInViewport)
    }
  }

  observeVideo() {
    this.loadVideo()
    $(window).on('scroll', () => {
      this.loadVideo()
    })
  }

  eventPlayOrPauseVideoOnDesktop(isInViewport) {
    const videoAutoplay = this.$el.find('[data-autoplay="1"]')
    if (videoAutoplay.length > 0) {
      if (isInViewport) {
        this.playVideo()
      } else {
        this.pauseVideo()
      }
    }
  }

  controlVideo() {
    if (this.$controlVideo.length) {
      this.$controlVideo.on('click', () => {
        if (this.player) {
          if (this.play) {
            this.pauseVideo()
          } else {
            this.playVideo()
          }
        }
      })
    }
  }

  clickThumb() {
    if (this.$imgVideo.length) {
      this.$imgVideo.on('click', () => {
        this.play = false
        if (this.player) {
          if (this.play) {
            this.pauseVideo()
          } else {
            this.playVideo()
          }
        }
        if (!this.$imgVideo.hasClass('hidden')) {
          this.$imgVideo.addClass('hidden')
          this.$controlVideo.addClass('hidden')
        }
      })
    }
  }

  controlVideoMute() {
    if (this.$controlVideoMute.length) {
      this.$controlVideoMute.on('click', () => {
        if (this.player) {
          if (this.mute) {
            this.unMuteVideo()
          } else {
            this.muteVideo()
          }
        }
      })
    }
  }

  playVideo() {
    if (!this.play && this.player) {
      if (this.mySwiper && !this.$el.hasClass('swiper-slide-active') || !this.isInViewport(this.$el.get(0))) {
        return
      }
      if (this.player.playVideo) {
        this.player.playVideo()
        this.play = true
      }
      this.$textControl.text('Pause video')
      this.$controlVideo.attr('aria-pressed', 'true')
    }
  }

  pauseVideo() {
    if (this.play && this.player) {
      if (this.player.pauseVideo) {
        this.player.pauseVideo()
        this.play = false
      }
      this.$textControl.text('Play video')
      this.$controlVideo.attr('aria-pressed', 'false')
    }
  }

  muteVideo() {
    if (!this.mute) {
      if (this.player.isMuted && !this.player.isMuted()) {
        this.player.mute()
        this.mute = true
      }
    }
  }

  unMuteVideo() {
    if (this.mute) {
      if (this.player.isMuted && this.player.isMuted()) {
        this.player.unMute()
        this.mute = false
      }
    }
  }

  // Thêm method để cleanup nếu cần
  destroy() {
    if (this.youtubeCheckId) {
      cancelAnimationFrame(this.youtubeCheckId)
    }
  }
}
