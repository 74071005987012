export default class AnimationScrollPage {
  constructor() {
    this.$elems = $('.animation')
    this.winH = window.innerHeight
    this.winW = window.innerWidth
    this.offset = window.innerHeight
    this.wintop = null
    this.topcoords = null
    this.addClassAni = 'set-animation'
  }

  init() {
    this.animationEle()
    $(window).on('scroll resize', () => {
      this.animationEle()
    })
  }

  animationNumber($el) {
    const numberCount = parseFloat($el.attr('data-numbercounter'))
    $el.addClass('counted')
    $el.prop('Counter', 0).animate({
      Counter: numberCount,
    }, {
      duration: 2200,
      easing: 'swing',
      step: (now) => {
        $el.text(Math.ceil(now))
      },
      complete: () => {
        $el.text(numberCount)
      },
    })
  }

  animationEle() {
    this.offset = this.winH
    this.wintop = $(window).scrollTop()
    this.$elems.each((_index, ele) => {
      const $elm = $(ele)
      if ($elm.hasClass(this.addClassAni)) {
        return true
      }
      this.topcoords = $elm.offset().top
      if (this.wintop > this.topcoords - this.offset) {
        $elm.addClass(this.addClassAni)
        if ($elm.hasClass('centered-stats-value') && !$elm.hasClass('counted')){
          this.animationNumber($elm)
        }
      }
      return true
    })
  }
}

new AnimationScrollPage().init()
