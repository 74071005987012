export const getPosition = (element) => {
  let xPosition = 0
  let yPosition = 0
  let tmpElm = element

  while (tmpElm || $(tmpElm).hasClass('.locomotive-scroll')) {
    xPosition += tmpElm.offsetLeft - tmpElm.scrollLeft + tmpElm.clientLeft
    yPosition += tmpElm.offsetTop - tmpElm.scrollTop + tmpElm.clientTop
    tmpElm = tmpElm.offsetParent
  }

  return { x: xPosition, y: yPosition }
}
export const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?v=|watch\/))([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false
}

export const getVimeoVideoId = (url) => {
  const regExp = /https?:\/\/(?:www\.)?(?:player\.)?vimeo(?:\.com|pro)?\/(?:[^\d]+)?(\d+)(?:.*)?/i
  const match = url.match(regExp)
  if (match && match[1]) {
    return match[1]
  }
  return null
}

export const isDesktop = () => $(window).innerWidth() > 1199.5
export const isTablet = () => $(window).innerWidth() > 767 && $(window).innerWidth() < 1200

export const isLandScape = () => window.innerWidth > window.innerHeight

export const timingDelay = (duration, callbackFn) => {
  let start
  const animStep = (timestamp) => {
    if (!start) {
      start = timestamp
    }
    const time = timestamp - start
    if (time < duration) {
      window.requestAnimationFrame(animStep)
    } else {
      callbackFn()
    }
  }
  window.requestAnimationFrame(animStep)
}

export const easeInOutQuad = (x) => x < 0.5 ? 2 * x * x : 1 - ((-2 * x + 2) ** 2) / 2

export const uuidv4 = () => `${'xxxx'.replace(/x/g, () => Math.floor((Math.random() * 10e9)).toString(20))}`

export const toSlug = (str) => str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')